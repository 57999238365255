import type { CountdownRef } from '@south-street-app/molecules';

import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useRef } from 'react';

type CountdownContextType = {
  countdownRef: React.RefObject<CountdownRef>;
};

const CountdownContext = createContext<CountdownContextType | undefined>(
  undefined,
);

const CountdownProvider = ({ children }: PropsWithChildren) => {
  const countdownRef = useRef<CountdownRef>(null);

  return (
    <CountdownContext.Provider value={{ countdownRef }}>
      {children}
    </CountdownContext.Provider>
  );
};

function useCountdownRef() {
  const context = useContext(CountdownContext);

  if (!context) {
    throw new Error('useCountdownRef must be used within a CountdownProvider');
  }

  return context.countdownRef;
}

export { CountdownProvider, useCountdownRef };
