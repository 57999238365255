import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { XStack, YStack } from 'tamagui';

import { Icon, LabelM } from '@south-street-app/atoms';
import { useMobileTradeStore } from '@south-street-app/stores';

type CountdownProps = {
  countdownSeconds: number;
  onCompleted?: () => void;
};

type CountdownRef = {
  stop: () => void;
};

const Countdown = forwardRef<CountdownRef, CountdownProps>(
  ({ countdownSeconds, onCompleted }, ref) => {
    const countdownMilliseconds = countdownSeconds * 1000;
    const [targetTime, setTargetTime] = useState(
      () => new Date().getTime() + countdownMilliseconds,
    );
    const [currentTime, setCurrentTime] = useState(new Date().getTime());

    const isNewQuotedPrice = useMobileTradeStore(
      (state) => state.isNewQuotedPrice,
    );

    // Store the interval's ID so we can clear it imperatively
    const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const startInterval = () => {
      intervalIdRef.current = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 1000);
    };

    const stopInterval = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);

        intervalIdRef.current = null;
      }
    };

    useImperativeHandle(ref, () => ({
      stop() {
        stopInterval();
      },
    }));

    // Reset the timer whenever a new quoted price is detected
    useEffect(() => {
      if (isNewQuotedPrice) {
        setCurrentTime(new Date().getTime());

        setTargetTime(() => new Date().getTime() + countdownMilliseconds);

        startInterval();
      }
    }, [countdownMilliseconds, isNewQuotedPrice]);

    useEffect(() => {
      startInterval();

      return () => {
        stopInterval();
      };
    }, []);

    // Calculate remaining time
    const remainingTime = targetTime - currentTime;
    const minutes = Math.floor(remainingTime / 1000 / 60);
    const seconds = Math.floor((remainingTime / 1000) % 60);

    useEffect(() => {
      if (remainingTime <= 0) {
        onCompleted?.();
      }
    }, [onCompleted, remainingTime]);

    return (
      <XStack
        borderRadius={getSpaceTokenValue('$10')}
        backgroundColor={'$mono100'}
        paddingHorizontal={'$4'}
        paddingVertical={'$3'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'$25'}
      >
        <Icon iconName={'timerIcon'} size={'$6'} color={'$brand500'} />
        <YStack alignItems={'center'} paddingHorizontal={'$1'}>
          <LabelM fontSize={'$4'} color={'$brand500'}>
            {minutes < 0 ? '00' : minutes < 10 ? '0' + minutes : minutes}
            {':'}
            {seconds < 0 ? '00' : seconds < 10 ? '0' + seconds : seconds}
          </LabelM>
        </YStack>
      </XStack>
    );
  },
);

Countdown.displayName = 'Countdown';

export { Countdown };
export type { CountdownRef };
