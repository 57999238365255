import { useCallback, useEffect, useRef, useState } from 'react';

import { Pressable } from 'react-native';

import { useAuthStore } from '@utility-nyc/react-auth-storage';

import { BodyL } from '@south-street-app/atoms';
import { getSizeTokenValue } from '@south-street-app/theme';
import { useSendOtp } from '@utility-nyc/react-query-sdk';

type ResendcodeButtonProps = {
  email: string;
  password: string;
  countdownTime?: number;
};

type TimerReturnType = ReturnType<typeof setInterval> | undefined;

const ResendcodeButton = ({
  email,
  password,
  countdownTime = 60,
}: ResendcodeButtonProps) => {
  const [countdown, setCountdown] = useState(countdownTime);

  const { mutate: sendCredentials } = useSendOtp();

  const timer = useRef<TimerReturnType>();

  const resetTimer = useCallback(() => {
    clearInterval(timer.current);

    setCountdown(countdownTime);
  }, [countdownTime]);

  const startCounting = useCallback(() => {
    timer.current = setInterval(() => {
      setCountdown((prev) => Math.max(prev - 1, 0));
    }, 1000);
  }, []);

  // On mount
  useEffect(() => {
    resetTimer();

    startCounting();
  }, [resetTimer, startCounting]);

  const handleResend = useCallback(() => {
    resetTimer();

    startCounting();

    useAuthStore.getState().setTokens({
      accessToken: '',
    });

    sendCredentials({
      data: {
        identifier: email,
        credential: password,
      },
    });
  }, [resetTimer, startCounting, sendCredentials, email, password]);

  return (
    <Pressable
      style={{ paddingTop: getSizeTokenValue('$4') }}
      onPress={countdown > 0 ? undefined : handleResend}
      disabled={countdown > 0}
    >
      <BodyL
        userSelect={'none'}
        color={countdown > 0 ? '$mono400' : '$brand500'}
      >
        {`Resend code ${countdown > 0 ? `in ${countdown}s` : ''}`}
      </BodyL>
    </Pressable>
  );
};

export { ResendcodeButton };
