import React from 'react';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { Spacer, YStack } from 'tamagui';

import { Countdown } from '@south-street-app/molecules';
import { useCountdownRef } from '@south-street-app/providers';

import { DisplayItem } from './DisplayItem';

type TradeCardItem = {
  label: string;
  title: string | undefined;
};

type TradeCardProps = {
  items: TradeCardItem[];
  countdownSeconds?: number;
  onCountdownCompleted?: () => void;
};

const TradeCard = ({
  items,
  countdownSeconds,
  onCountdownCompleted,
}: TradeCardProps) => {
  const countdownRef = useCountdownRef();

  return (
    <YStack
      backgroundColor={'$brand500'}
      borderRadius={getSpaceTokenValue('$4')}
      paddingHorizontal={'$6'}
      paddingVertical={'$10'}
    >
      <YStack space={'$4'}>
        {items.map((item, index) => (
          <DisplayItem key={index} label={item.label} title={item.title} />
        ))}
      </YStack>
      {countdownSeconds ? (
        <>
          <Spacer size={'$4'} />
          <Countdown
            ref={countdownRef}
            countdownSeconds={countdownSeconds}
            onCompleted={onCountdownCompleted}
          />
        </>
      ) : null}
    </YStack>
  );
};

export { TradeCard };
